<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    style="padding-top: 0;padding-bottom: 0">
    <advert-add
      ref="addView"
      v-model="addAdvertViewShow"
      @viewClosed="hideDialog"/>
    <v-layout
      justify-center
      wrap>
      <v-flex
        md12
        style="padding-top: 0;padding-bottom: 0">
        <v-card-title
          style="padding: 0">
          <v-flex
            xs12
            md3>
            <v-btn
              class="mx-3  font-weight-medium"
              color="green"
              @click.prevent="showDialog">
              <v-icon>mdi-plus-box-outline</v-icon>
              <span class="mx-2 font-weight-medium">发布广告</span>
            </v-btn>
          </v-flex>
          <v-flex
            xs12
            md6>
            <material-datepicker
              ref="datePicker"
              v-model="range"
              :input-props="inputProps"
              :range="true"
              language="zh-cn"
              start-label="开始日期"
              end-label="结束日期"
              separator-label="/"
              class="picker picker-range"
              min="2019-01-01"
              max="2030-01-01"
              @input="dateInput"/>
          </v-flex>
          <v-flex
            xs12
            md3>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="智能模糊搜索"
              single-line
              hide-details/>
          </v-flex>
        </v-card-title>
        <material-card
          color="green"
          style="margin-top: 30px">
          <div
            slot="header"
            class="d-flex">
            <span>
              <div class="title mb-2">
                广告信息
              </div>
            </span>
            <span
              style="text-align:right;margin-top: -10px">
              <div>
                <span style="margin-right:20px;">
                  <v-btn
                    small
                    round
                    class="mx-0 font-weight-medium"
                    color="transparent"
                    style="margin-right:20px"
                    @click="getAdvertList">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </span>
              </div>
            </span>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :pagination.sync="pagination"
            :expand="expand"
            class="elevation-3 "
            hide-actions>
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="subheading font-weight-light text-success  text--darken-3 text-xs-center"
                v-text="header.text"/>
            </template>
            <template
              v-slot:items="rowsprops">
              <tr @click="showDetail(rowsprops)">
                <td/>
                <td>{{ rowsprops.item.index }}</td>
                <td>{{ rowsprops.item.adName }}</td>
                <td>{{ rowsprops.item.duration }}</td>
                <td>{{ rowsprops.item.startTime }}</td>
                <td>{{ rowsprops.item.endTime }}</td>
                <td style="padding-top: 0;padding-bottom: 0">
                  <v-btn
                    slot="activator"
                    class="v-btn--small"
                    color="red"
                    icon
                    @click.stop="delAdvertShow(rowsprops)">
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template
              v-slot:expand="rowsprops">
              <v-card>
                <v-container
                  fill-height
                  fluid
                  pa-2>
                  <v-layout fill-height>
                    <v-flex
                      xs12
                      md2>
                      <v-card-text
                        width="100"
                        style="float: left">
                        <v-img
                          :src="rowsprops.item.img"
                          height="100"
                          width="100"
                          style="background: #eeeeee"
                          @click="$previewImageLite.preview(rowsprops.item.img)"/>
                      </v-card-text>
                    </v-flex>
                    <v-flex
                      xs12
                      md10>
                      <v-card-text
                        width="100"
                        style="float: left">
                        <span v-if="rowsprops.item.groupId">设备组<br>
                          {{ rowsprops.item.group }}</span>
                        <span v-else>设备名称<br>
                          {{ rowsprops.item.device }}</span><br>
                        <span>广告名称<br>
                          {{ rowsprops.item.file }}</span>
                      </v-card-text>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </template>
          </v-data-table>
          <v-layout>
            <v-flex
              xs12
              md1>
              <v-select
                v-model="pagination.rowsPerPage"
                :items="selectedItems"
                label="每页"
                @change="selectChanged"/>
            </v-flex>

            <v-flex
              xs12
              md11
              class="text-xs-right">
              <div class=" pt-2 ">
                <v-pagination
                  v-model="pagination.page"
                  :length="pages"
                  sort-by=""/>
              </div>
            </v-flex>
          </v-layout>
        </material-card>
      </v-flex>
    </v-layout>
    <!--弹出的删除确认框 S-->
    <v-dialog
      v-model="showhide.DeleteDialog"
      max-width="600"
      persistent
      @keydown.esc="showhide.DeleteDialog = false">
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green">
            <div
              slot="header">
              <div class="mb-2 d-flex">
                <span class="title mb-2">广告名验证</span>
                <span class="text-xs-right">
                  <v-icon
                    size = "24"
                    @click = "delAdvertHide">
                    mdi-close-circle
                  </v-icon>
                </span>
              </div>
              <div>
                <p class="category font-weight-medium">输入广告名后确认删除：<span class="title text-warning font-weight-bold">{{ deleteDialog.confirmadname }}</span></p>
              </div>
            </div>
            <v-flex
              xs12
              md12>
              <v-text-field
                v-focus
                ref="delinput"
                v-model="deleteDialog.adname"
                label="广告名"
                required
                @keyup.enter="delAdvert"/>
              <div style="display: none">
                <v-text-field
                  v-model="deleteDialog.confirmadname"
                  label="广告名"/>
              </div>
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px">
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="delAdvert">
              确认删除
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出的删除确认框 E-->
  </v-container>
</template>

<script>
import Vue from 'vue'
import advertAdd from './AdvertAdd'
Vue.component('advert-add', advertAdd)
export default {
  name: 'AdvertManagement',
  directives: {
    open: {
      // directive definition
      bind: function (el, binding) {
        // console.log('open: bind: ', el, binding)
        binding.value.expanded = false
      }
    }
  },
  data: () => ({
    // test: 1,
    today: '2019-10-01',
    range: ['2019-10-01', '2019-10-01'],
    rangeAdd: ['2019-10-01', '2019-10-01'],
    addAdvertViewShow: false,
    showhide: {
      DeleteDialog: false
    },
    deleteDialog: {
      adname: '',
      confirmadname: '',
      adId: ''
    },
    inputProps: {
      solo: false,
      style: { width: '240px' }
    },
    menuProps: {
      offsetY: true,
      closeOnContentClick: false
    },
    defaultPresets: [
      { label: '今天', range: [ '2019-10-10', '2019-10-10' ] },
      { label: '未来2天', range: [ '2019-10-10', '2019-10-11' ] },
      { label: '未来1周', range: [ '2019-10-10', '2019-10-16' ] },
      { label: '未来2周', range: [ '2019-10-10', '2019-10-23' ] },
      { label: '未来1月', range: [ '2019-10-10', '2019-11-09' ] }
    ],
    search: '',
    pagination: {
      page: 3,
      rowsPerPage: 5,
      totalItems: '',
      sortBy: ''
    },
    expand: true,
    selectedItems: ['5', '10', '20', '50', '100'],
    headers: [
      { sortable: false, text: '', value: '', width: '3%' },
      { sortable: true, text: '#', value: 'index', width: '5%' },
      { sortable: true, text: '名称', value: 'adName', width: '25%' },
      { sortable: true, text: '时长', value: 'duration', width: '10%' },
      { sortable: true, text: '开始', value: 'startTime', width: '20%' },
      { sortable: true, text: '结束', value: 'endTime', width: '20%' },
      { sortable: false, text: '删除', value: '', width: '10%' }
      // { sortable: false, text: '审核', value: '', width: '10%' }
    ],
    items: []
  }),
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) return 0
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    }
  },
  created () {
    let today = new Date().setHours(0, 0, 0, 0)
    let start = Math.round(new Date(today).getTime())
    let end = start + 1000 * 60 * 60 * 24 * 7 - 1
    this.range = [this.$getDateString(start), this.$getDateString(end)]
    this.rangeAdd = [this.$getDateString(start), this.$getDateString(end)]
    this.today = this.$getDateString(today)
    this.getAdvertList()
  },
  methods: {
    dateInput (res) {
      // console.log('dateInput(): ', res)
      // console.log('this.range: ', this.range)
      this.getAdvertList()
    },
    selectChanged () {
      let maxPage = Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      if (this.pagination.page > maxPage) {
        this.pagination.page = maxPage
      }
      this.$localStorage.set(this.$session.get('signIn').username + '_device_rowsPerPage', this.pagination.rowsPerPage)
    },
    getAdvertList () {
      this.items = []
      // let today = new Date().setHours(0, 0, 0, 0)
      // let start = Math.round(new Date(today).getTime()) - 1000 * 60 * 60 * 24 * 20
      // let end = start + 1000 * 60 * 60 * 24 * 10
      let start = Math.round(new Date(this.range[0] + ' 00:00:00').getTime())
      let end = Math.round(new Date(this.range[1] + ' 23:59:59.999').getTime())
      // console.log('start: ', start)
      // console.log('end: ', end)
      this.$http
        .get(this.$store.state.app.url + '/mediaSystem/advert/advertList?page=1&size=2000' + '&start=' + start + '&end=' + end, this.$authHeaders())
        .then(result => {
          // console.log(result)
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < res.detail.list.length; i++) {
              let obj = {
                'id': res.detail.list[i].id,
                'fileId': res.detail.list[i].fileId,
                'file': '',
                'img': '',
                'deviceId': res.detail.list[i].deviceId,
                'device': '',
                'groupId': res.detail.list[i].groupId,
                'group': '',
                'adName': res.detail.list[i].adName,
                'duration': res.detail.list[i].duration + '秒',
                'detail': false,
                'startTime': this.$getDateTimeString(res.detail.list[i].startTime),
                'endTime': this.$getDateTimeString(res.detail.list[i].endTime),
                'index': i + 1
              }
              this.items.push(obj)
            }
            this.pagination.totalItems = res.detail.list.length
            let rowsPerPage = this.$localStorage.get(this.$session.get('signIn').username + '_device_rowsPerPage')
            if (rowsPerPage === '' || rowsPerPage === null) {
              this.pagination.rowsPerPage = '5'
            } else {
              this.pagination.rowsPerPage = rowsPerPage
            }
            // console.log(res.message)
          } else {
            console.error('result.data.code: ', result.data.code)
          }
        })
        .catch(error => {
          // console.log('catch(error): ')
          this.$httpCatch(error)
        })
    },
    delAdvertHide () {
      this.showhide.DeleteDialog = false
    },
    delAdvertShow (rowsprops) {
      // console.log('delAdvert() ', rowsprops)
      this.deleteDialog.adname = ''
      this.deleteDialog.confirmadname = rowsprops.item.adName
      this.deleteDialog.adId = rowsprops.item.id
      this.showhide.DeleteDialog = true
    },
    delAdvert () {
      if (this.deleteDialog.adname !== this.deleteDialog.confirmadname) {
        this.$snackbar.warning('输入正确的广告名称')
        return
      }
      this.showhide.DeleteDialog = false
      let formdata = new FormData()
      formdata.append('id', this.deleteDialog.adId)
      formdata.append('adName', this.deleteDialog.adname)
      this.$http
        .post(this.$store.state.app.url + '/mediaSystem/advert/delAdvert', formdata, this.$authHeaders())
        .then(result => {
          // console.log(result)
          let res = result.data
          if (res.code === 0) {
            console.info('result.data.code: ', result.data.code)
            this.getAdvertList()
          } else {
            console.error('result.data.code: ', result.data.code)
          }
        })
        .catch(error => {
          this.$httpCatch(error)
        })
    },
    showDetail (rowsprops) {
      // console.log('showDetail() ', rowsprops.expanded)
      if (rowsprops.expanded === true) {
        rowsprops.expanded = false
        return
      }
      rowsprops.expanded = !rowsprops.expanded
      if (rowsprops.item.detail) {
        return
      }
      this.$http
        .get(this.$store.state.app.url + '/mediaSystem/advert/getAdvertDetail?id=' + rowsprops.item.id, this.$authHeaders())
        .then(result => {
          if (result.data.code === 0) {
            // console.log('showDetail() result: ', result.data.detail)
            rowsprops.item.img = result.data.detail.thumbnail
            rowsprops.item.file = result.data.detail.fileName
            rowsprops.item.group = result.data.detail.groupName
            rowsprops.item.device = result.data.detail.deviceSerialCode
            rowsprops.item.detail = true
          } else {
            console.error('result.data.code: ', result.data.code)
          }
        })
        .catch(error => {
          this.$httpCatch(error)
        })
    },
    showDialog () {
      // console.log('showDialog(): ')
      this.addAdvertViewShow = true
      // this.$refs.addView.renew(true)
      // this.range = [this.$getDateString(start), this.$getDateString(end)]
      // this.$refs.datePicker.renew(this.rangeAdd)
    },
    hideDialog (value) {
      // console.log('hideDialog(): ')
      if (!this.$isEmpty(value)) {
        this.getAdvertList()
        // console.log('hideDialog(): ', value)
        // let obj = {
        //   'id': value.id,
        //   'fileId': value.fileId,
        //   'file': '',
        //   'img': '',
        //   'deviceId': value.deviceId,
        //   'device': '',
        //   'groupId': value.groupId,
        //   'group': '',
        //   'adName': value.adName,
        //   'duration': value.duration + '秒',
        //   'detail': false,
        //   'startTime': this.$getDateTimeString(value.startTime),
        //   'endTime': this.$getDateTimeString(value.endTime),
        //   'index': '*'
        // }
        // this.items.unshift(obj)
      }
      this.addAdvertViewShow = false
    }
  }
}
</script>

<style scoped>

</style>
